<template>
  <div class="container is-fluid home">
    <div class="columns main-navigation ">
      <div class="column is-6-fullscreen is-4-fullhd is-6-desktop is-6-tablet is-12-mobile">
        <div class="columns is-multiline is-mobile">
          <div class="column is-6" v-for="(link, key) in navigation" :key="key">
            <app-link :link="link" />
          </div>
        </div>
      </div>
      <div class="column is-4 is-offset-2 news-carousel">
        <news-carousel />
      </div>
      
    </div>

    <div id="new-portal" class="mx-4 cursor-pointer" @click="navigateToNewPortal">
      <div style="color: rgb(0,157,224)">Innovation is in our DNA</div>
      <div class="is-flex is-align-items-center">
        <div class="text-white mr-2">Probeer ons nieuwe bestel portaal</div>
      </div>
    </div>

  </div>
</template>

<script>
import AppLink from "../components/AppLink";
import NewsCarousel from "../components/News/NewsCarousel";

export default {
  data() {
    return {
      navigation: [
        ...this.$router.options.routes.filter(route => route.meta.home)
      ],
    };
  },
  methods: {
    navigateToNewPortal() {
      const token = localStorage.getItem('token');
      window.location.href = 'https://beta.bigimpact.com/' + 'login?token=' + token;
    }
  },
  components: {
    AppLink,
    NewsCarousel
  }
};
</script>

<style lang="scss">
@import "~vars";
@import "~bulma/sass/utilities/mixins";

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#new-portal {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1rem;
}

@include mobile() {
  .main-navigation {
    padding: 0 0.75rem;
    margin-top: 1rem;
  }

  .news-carousel {
    display: flex;
    justify-content: center;

    .card {
      max-width: 90%;
    }
  }
}

</style>
